import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http'
import { AppRoutingModule } from './app-routing.module';
import { RouterModule, Routes} from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatIconModule, MatInputModule, MatAutocompleteModule, MatChipsModule, MatFormFieldModule } from '@angular/material';

import { AppComponent } from './app.component';
import { NavbarComponent } from './navbar/navbar.component';
import { ThumbnailsComponent } from './thumbnails/thumbnails.component';
import { JumbotronComponent } from './jumbotron/jumbotron.component';
import { FooterComponent } from './footer/footer.component';
import { ArticleComponent } from './article/article.component';
import { WebglComponent } from './webgl/webgl.component';
import { SearchComponent } from './search/search.component';
import { BootstrapSearchComponent } from './bootstrap-search/bootstrap-search.component';

const appRoutes: Routes = [
  { path: 'tutorials', component: ArticleComponent },
  { path: 'webgl', component: WebglComponent },
  { path: '', component: ThumbnailsComponent},
  { path: '',  redirectTo: '', pathMatch: 'full' },
];

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    ThumbnailsComponent,
    JumbotronComponent,
    FooterComponent,
    ArticleComponent,
    WebglComponent,
    SearchComponent,
    BootstrapSearchComponent
  ],
  imports: [
    RouterModule.forRoot(
      appRoutes,
      {enableTracing: true}
    ),
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule, MatInputModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatFormFieldModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
