import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bootstrap-search',
  templateUrl: './bootstrap-search.component.html',
  styleUrls: ['./bootstrap-search.component.css']
})
export class BootstrapSearchComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
