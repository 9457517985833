import { Component, OnInit, ViewChild, ElementRef, EventEmitter, Output } from '@angular/core';
import { SearchService } from './search.service'
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { Post } from './post';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit {
  @ViewChild('autocompleteInput') autocompleteInput: ElementRef;
  @Output() onSelectedOption = new EventEmitter();

  myControl = new FormControl();
  filteredOptions: Observable<string[]>;
  allPosts: Post[];
  autoCompleteList: any[];

  constructor(private searchService: SearchService) { }

  ngOnInit() {
    // get all the post
    this.searchService.getAll().subscribe(posts => {
      this.allPosts = posts;
    });

    // when user types something
    this.myControl.valueChanges.subscribe(userInput => {
      this.autoCompleteExpenseList(userInput);
    })
  }

  filterPostList(event) {
    var posts = event.source.value;
    if (!posts) {
        this.searchService.searchOption = []
    }
    else {

        this.searchService.searchOption.push(posts);
        this.onSelectedOption.emit(this.searchService.searchOption)
    }
    this.focusOnPlaceInput();
  }

  private autoCompleteExpenseList(input) {
    let categoryList = this.filterCategoryList(input)
    this.autoCompleteList = categoryList;
  }

  // this is where filtering the data happens according to you typed value
  filterCategoryList(val) {
    var categoryList = []
    if (typeof val != "string") {
        return [];
    }
    if (val === '' || val === null) {
        return [];
    }
    return val ? this.allPosts.filter(s => s.title.toLowerCase().indexOf(val.toLowerCase()) != -1)
        : this.allPosts;
  }

  displayFn(post: Post) {
    let k = post ? post.title : post;
    return k;
  }

  // focus the input field and remove any unwanted text.
  focusOnPlaceInput() {
    this.autocompleteInput.nativeElement.focus();
    this.autocompleteInput.nativeElement.value = '';
  }

  removeOption(option) {

    let index = this.searchService.searchOption.indexOf(option);
    if (index >= 0)
        this.searchService.searchOption.splice(index, 1);
    this.focusOnPlaceInput();

    this.onSelectedOption.emit(this.searchService.searchOption)
  }
}
